<template>
  <!--服务配置 模块-->
  <div>
    <el-card shadow="never">
      <el-row :gutter="15" class="xiabianjustyle">
        <el-col :span="8">
          <span class="rightmargin">是否开启聚合：</span>
          <el-radio v-model="radio" label="1">是</el-radio>
          <el-radio v-model="radio"label="2">否</el-radio>
        </el-col>
      </el-row>
      <el-row :gutter="15" class="xiabianjustyle">
        <el-col :span="8">
          <span class="rightmargin">是否开启抢单：</span>
          <el-radio v-model="radio1" label="1">是</el-radio>
          <el-radio v-model="radio1"label="2">否</el-radio>
        </el-col>
      </el-row>
      <el-row :gutter="15" class="xiabianjustyle">
        <el-col :span="8">
          <span class="rightmargin">聚合的类型：</span>
          <el-checkbox v-model="checked1">事故救援</el-checkbox>、
          <el-checkbox v-model="checked2">非事故救援</el-checkbox>
          <el-checkbox v-model="checked3">高速救援</el-checkbox>
        </el-col>
      </el-row>
      <el-row :gutter="15" class="xiabianjustyle">
        <el-col :span="8">
          <span class="rightmargin">聚合项目：</span>
          <el-checkbox v-model="checked4">拖车</el-checkbox>、
          <el-checkbox v-model="checked5">搭电</el-checkbox>
          <el-checkbox v-model="checked6">送油</el-checkbox>
        </el-col>
      </el-row>
      <el-row :gutter="15" class="xiabianjustyle">
        <el-col :span="8">
          区域选择 类似计价公式
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  props:{
    // 修改回显的数据
    data: Object,
    // 父级id
    id: Number,
  },
  data(){
    return{
      radio:'',
      radio1:'',
      checked1:false,
      checked2:false,
      checked3:false,
      checked4:false,
      checked5:false,
      checked6:false,

      //字典
      dict: {
        type:{}
      },

    }
  },

  mounted() {
    // 获取服务商
    // this.getFuwushang();

    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '客户字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

  },

  methods:{



  }

}
</script>

<style lang="scss" scoped>
  .rightmargin{
    display: inline-block;
    margin-right: 15px;
  }
  .xiabianjustyle{
    margin-bottom: 15px;
  }
</style>
